<template>
<div class="main">
  <Tree service="/sys/sysOrganization/dept" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
    <vxe-modal ref="xModal" title="设置权限" width="800" height="600" show-zoom>
      <template #default>
        <SetPermission ref="permission" :selectRow="selectRow" />
      </template>
    </vxe-modal>
  </div>
</div>
</template>

<script>
import SetPermission from './SetPermission';
export default {
  components: {
    SetPermission
  },
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "角色名称",
            minWidth: 160,
            align: "left",
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: '请输入角色名称'
              },
            },
          },
          {
            field: "code",
            title: "角色标识",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: '请输入角色标识'
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            width: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: '请输入排序号'
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row);
          //       }
          //     }
          //   },
          // },
          {
            field: "updateTime",
            title: "更新日期",
            width: 200,
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "setPermission",
                  status: "primary",
                  label: "设置权限",
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    },
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        treeConfig: {
          children: "children",
          accordion: false,
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "organizationId",
            title: "所属企业",
            span: 24,
            itemRender: {
              name: "$VxeSelectParent",
              expandAll: true,
              props: {
                placeholder: "请选择所属企业",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                },
              }
            },
          },
          {
            field: "name",
            title: "角色名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入角色名称",
              },
            },
          },
          {
            field: "code",
            title: "角色代码",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入角色代码",
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入排序号",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          organizationId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入角色名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          code: [
            { required: true, message: "请输入角色代码" },
            { min: 1, max: 24, message: "长度在 1 到 24 个字符" },
          ],
          orderCode: [{ required: true, message: "请输入排序号" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          organizationId: "",
          name: "",
          code: "",
          type: "1",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "角色名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入角色名称",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   itemRender: {
          //     name: "$select",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       placeholder: "请选择状态",
          //     },
          //   },
          // },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          enabled: "",
        },
      },
      selectRow: null,
    };
  },
  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "organizationId");
      this.formOptions.data['organizationId'] = node.id;
      this.$refs.xGrid.searchOptions.data["organizationId"] = node.id;
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style scoped>
</style>
