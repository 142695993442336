<template>
<div class="wrapper">
  <div class="table">
    <vxe-grid ref="menus" height="auto" v-bind="resourceOptions" :loading="loading" :data="resLists"></vxe-grid>
  </div>
  <vxe-form ref="xForm" @submit="submitForm" @reset="cancelEvent">
    <vxe-form-item align="center" span="24">
      <template v-slot>
        <vxe-button type="submit" status="primary">提交</vxe-button>
        <vxe-button type="reset">取消</vxe-button>
      </template>
    </vxe-form-item>
  </vxe-form>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from "axios";
export default {
  props: {
    originData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectRow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      resLists: [],
      orgLists: [],
      resourceOptions: {
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 80,
          },
          {
            type: "checkbox",
            title: "菜单名称",
            align: "left",
            minWidth: 200,
            treeNode: true,
          },
        ],
        checkboxConfig: { labelField: "name", trigger: 'row' },
        treeConfig: {
          children: "children",
          accordion: false,
        },
      },
    }
  },
  created() {
    this.getLists();
  },
  methods: {
    ...mapActions(['getTreeService', 'findByEventService', 'submitEventService']),

    // 获取所有菜单树
    getLists() {
      this.loading = true;
      return axios
        .all([
          this.$Axios.post('/sys/sysAppResource/limit/tree', { enabled: 1 }),
          this.$Axios.post(`/sys/sysRoleResource/findBy`, { "roleId": this.selectRow.id }),
        ])
        .then((data) => {
          if (data[0].code == 200 && data[1].code == 200) {
            this.resLists = data[0].data;
            return Promise.resolve({
              code: 200,
              data1: data[0].data,
              data2: data[1].data,
            });
          }
          return Promise.reject(data[0].message || data[1].message);
        }).then(({ code, data1, data2 }) => {
          if (code == 200) {
            data1.forEach(item => {
              this.setCheckboxRow(data2["resourceList"], item)
            });
          }
          this.resetEvent();
        });
    },

    // 递归操作，无限极设置默认选中状态
    setCheckboxRow(data, item) {
      if (item.children && item.children.length) {
        item.children.forEach(row => {
          if (data.indexOf(row.id) > -1) {
            this.setCheckboxRow(data, row)
          }
        })
      } else {
        if (data.indexOf(item.id) > -1) {
          this.$refs.menus.setCheckboxRow(item, true);
        }
      }
    },

    // 表单提交
    submitForm() {
      // 获取菜单的选择项
      let depSelectRecords = this.$refs.menus.getCheckboxRecords({ isFull: true });
      let depIndeterminates = this.$refs.menus.getCheckboxIndeterminateRecords({ isFull: true });
      let recordIds = [];
      depSelectRecords.forEach(item => {
        recordIds.push(item.id);
      });
      depIndeterminates.forEach(item => {
        recordIds.push(item.id);
      });

      this.loading = true;
      this.submitEventService({
          service: "/sys/sysRoleResource",
          params: {
            "roleId": this.selectRow.id,
            "resourceIds": recordIds,
          }
        })
        .then(res => {
          if (res.code == 200) {
            this.$message.success("保存成功");
            localStorage.resources = JSON.stringify(res.data);
            this.$store.state.menuLists = res.data;
            this.cancelEvent();
          }
          this.resetEvent();
        })
        .catch(err => {
          this.resetEvent();
        });
    },

    cancelEvent() {
      this.$parent.$parent.$refs.xModal.close();
    },

    // 重置表单
    resetEvent() {
      this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
}
</style>
